import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import styles from "./blog-post.module.scss"
import SEO from '../components/seo'
import { MDXRenderer } from "gatsby-plugin-mdx"
import classNames from "classnames"

import { FiPenTool } from 'react-icons/fi'


export default ({ pageContext, data }) => {
  const post = data.mdx
  const defaultImage = data.defaultImage.childImageSharp.resize.src
  const { next, previous } = pageContext
  const category = post.frontmatter.category || "Mine minne"

  const genre = classNames({
    [`${styles.bodytext}`]: true,
    [`${styles.poem}`]: post.frontmatter.genre === "poem"
  })
    
  const nextlink = next && <div className={styles.next}>
          <span>Neste kapittel</span>
          <AniLink swipe direction="left" to={ `/minne/${next.frontmatter.slug}/` }>
            { next.frontmatter.title }
          </AniLink>
        </div>

  const prevlink = previous && <div className={styles.previous}>
          <span>Forrige kapittel</span>
          <AniLink swipe direction="right" to={ `/minne/${previous.frontmatter.slug}/` }>
            { previous.frontmatter.title }
          </AniLink>
        </div>
  const indexLink = <div className={styles.indexlink}>
    <span>Tilbake</span>
    <AniLink to={ `/minne/` } swipe direction="up">
      Innholdsfortegnelse
    </AniLink>
  </div>

  const author = (post.frontmatter.author ? post.frontmatter.author.display_name : null) || "Andreas Indregard"

  return(
    <Layout>
      <SEO title={ post.frontmatter.title } description={ post.excerpt } author="Andreas Indregard" image={ defaultImage } lang="nn" />
      <article id={ post.fields.slug } className={styles.blogPost}>
        <span className={styles.category}>{category}</span>
        <h1>{ post.frontmatter.title }</h1>
        <div className={styles.metadata}>
          <div className={styles.author}><FiPenTool /> { author }</div>
        </div>
        <div className={ genre }>
          <MDXRenderer>{ post.body }</MDXRenderer>
        </div>
      </article>

      <div className={styles.prevnext}>
        { prevlink }
        { indexLink }
        { nextlink }
      </div>

    </Layout>
  )
}


export const query = graphql`
  query($slug: String!) {
    defaultImage: file(relativePath: { eq: "bilder/01-andreas-ca-1950.jpg" }) {
      childImageSharp {
        resize(width: 900) {
          src
        }
      }
    }

    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        category
        sequence
        genre
        author {
          display_name
        }
      }
      fields {
        slug
      }
    }
  }
`
